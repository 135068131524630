
// NOTE: we wrapped the worker in this if statement to avoid the uncaught ReferenceError: importScripts is not defined error.
if (typeof window === 'undefined') {
  importScripts('https://www.gstatic.com/firebasejs/9.0.0/firebase-app-compat.js');
  importScripts('https://www.gstatic.com/firebasejs/9.0.0/firebase-messaging-compat.js');

  firebase.initializeApp({
    apiKey: 'AIzaSyD6pN4eCJLLOP7-dpG3YI2Ukff_Lg1Gy3E',
    authDomain: 'temptrackapp.firebaseapp.com',
    projectId: 'temptrackapp',
    storageBucket: 'temptrackapp.appspot.com',
    messagingSenderId: '15313714669',
    appId: '1:15313714669:web:b33eb21254959de2f4fbe4',
    // measurementId: 'G-B4T8JQR8SF',
  });

  const messaging = firebase.messaging();

  messaging.onBackgroundMessage(function (payload) {
    clients.matchAll({ type: 'window', includeUncontrolled: true }).then(function (clientList) {
      clientList.forEach(function (client) {
        client.postMessage({
          type: 'NOTIFICATION_RECEIVED',
          payload: payload,
        });
      });
    });
  });
}

